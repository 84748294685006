<template>
    <v-container fluid class="pa-0">
        <v-app-bar color="primary">
            <div class="text-h5 white--text"> Разметка пользователей</div>
        </v-app-bar>
        <v-container>
            <div class="text-body-1">
                Адрес портала-донора: {{ auth.domain }}
            </div>
            <div class="text-body-1 mb-2">
                Вебхук портала-акцептора: {{ hook }}
            </div>

            <v-text-field
                outlined
                prepend-inner-icon="mdi-magnify"
                label="Поиск по пользователям донора"
                style="max-width: 500px"
                v-model="search"
            >
            </v-text-field>

            <v-data-table
                :headers="headers"
                :items="data"
                :loading="tableLoading"
                :loading-text="loading_text"
                :search="search"
            >
                <template v-slot:item.donor="{ item }">
                    <v-list-item>
                        <v-list-item-avatar>
                            <v-avatar>
                                <img :src="item.PERSONAL_PHOTO"/>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.NAME }} {{ item.LAST_NAME }} {{ item.SECOND_NAME }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ item.EMAIL }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </template>
                <template v-slot:item.acceptors="{ item }">
                    <v-autocomplete
                        v-model="userMap[item.ID]"
                        label="Выберите пользователя"
                        :items="acceptor_users"
                        item-text="LAST_NAME"
                        item-value="ID"
                        :loading="acceptorLoading"
                    >
                        <template v-slot:selection="user">
                            <v-list-item>
                                <v-list-item-avatar>
                                    <v-avatar>
                                        <img :src="user.item.PERSONAL_PHOTO"/>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ user.item.NAME }} {{ user.item.LAST_NAME }} {{ user.item.SECOND_NAME }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ user.item.EMAIL }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <template v-slot:item="user">
                            <v-list-item v-on="user.on">
                                <v-list-item-avatar>
                                    <v-avatar>
                                        <img :src="user.item.PERSONAL_PHOTO"/>
                                    </v-avatar>
                                </v-list-item-avatar>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        {{ user.item.NAME }} {{ user.item.LAST_NAME }} {{ user.item.SECOND_NAME }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        {{ user.item.EMAIL }}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-autocomplete>
                </template>
            </v-data-table>
            <v-btn
                outlined
                @click="uploadUserData"
                style="float: right"
                :loading="sendData"
                v-if="!tableLoading && !acceptorLoading"
            > Начать выгрузку </v-btn>
        </v-container>
        <v-snackbar
            v-model="snackbar"
            timeout="-1"
        >
            <v-progress-circular size="24" indeterminate v-if="sendData"></v-progress-circular>
            {{ snackbarText }}
        </v-snackbar>
    </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
const axios = require('axios')
export default {
    name: 'UsersMapping',
    computed: { ...mapGetters(['auth', 'hook', 'acceptor_users', 'donor_users']) },
    data: () => ({
        search: '',
        snackbar: false,
        snackbarText: '',
        userMap: {},
        tableLoading: true,
        acceptorLoading: true,
        sendData: false,
        data: [],
        loading_text: 'Идет выгрузка пользователей',
        headers: [
            {text: 'Пользователь портала донора', align: 'start', sortable: false, value: 'donor'},
            {text: 'Пользователь портала акцептора', align: 'end', filterable: false, sortable: false, value: 'acceptors'},
        ]
    }),
    methods: {
        prepareUserMap() {
            for (let user in this.data) {
                if (user.ID !== undefined) {
                    this.userMap[user.ID] = null
                }
            }
        },
        uploadUserData: async function () {
            this.sendData = true
            var i,j, temporary, chunk = 20;
            let users = []
            for (const [key, value] of Object.entries(this.userMap)) {
                if (key !== null || value !== null) {
                    users.push({
                        Portal_donor: this.auth.domain,
                        Portal_acceptor: this.hook,
                        PortalDonorUserId: key,
                        PortalAcceptorUserId: value
                    })
                } else {
                    console.log(`Skip users ids ${key}: ${value}`)
                }
            }

            this.snackbar = true
            for (i = 0,j = users.length; i < j; i += chunk) {
                this.snackbarText = `Выгрузка пользователей. Готово: ${i > j ? 'Все' : i}`
                temporary = users.slice(i, i + chunk);
                let result = await axios.post('/api/mapUsers', temporary);
                console.log(result)
                if (result.status != 200) {
                    this.snackbarText = "Ошибка при выгрузке пользователей. Повторите попытку"
                    this.sendData = false
                    return
                }
            }

            this.snackbarText = `Начинаю миграцию...`
            axios.post('/api/upload/start', {
                event: 'start',
                domain_donor: this.auth.domain,
                token_donor: {
                    access_token: this.auth.access_token,
                    refresh_token: this.auth.refresh_token
                },
                domain_acceptor: this.hook,
                webhook: this.webhook
            }).then(() => {
                this.snackbar = false
                this.$router.push("/status")
            }).catch(() => {
                this.snackbarText = `Ошибка начала миграции...`
            })
        },
        tryAutomap() {
            for (let donor_user of this.donor_users) {
                for (let acceptor_user of this.acceptor_users) {
                    if (donor_user.EMAIL == acceptor_user.EMAIL) {
                        console.log(`Compare ${donor_user.EMAIL} with ${acceptor_user.EMAIL}: True`)
                        this.userMap[donor_user.ID] = acceptor_user.ID
                        break
                    } else {
                        console.log(`Compare ${donor_user.EMAIL} with ${acceptor_user.EMAIL}: False`)
                    }
                }
            }
        }
    },
    mounted() {
        this.$store.dispatch('getDonorUsers').then(() => {
            this.data = this.donor_users
            this.prepareUserMap()
            this.tableLoading = false
            this.$store.dispatch('getAcceptorUsers').then(() => {
                this.tryAutomap()
                this.acceptorLoading = false
            })
        }).catch(() => {
            this.loading_text = 'Ошибка при выгрузке пользователей'
        })
    }
}
</script>